import { clearHttpCache } from '@pkg/shared/dist/axios/cache-interceptor/indexed-db-storage'
import dayjs from 'dayjs'
import { defineStore } from 'pinia'
import { Subject, throttleTime } from 'rxjs'

import { auth as authApi, user as userApi } from '@/api'
import { MemberGroupApi } from '@/api/member-groups'
import { AuthSignUpApiPayload } from '@/interfaces/api/auth'
import { IMemberGroup } from '@/interfaces/api/member-groups'
import { GroupPermissionCode, MemberRole } from '@/interfaces/global'
import { SessionStore } from '@/interfaces/store/session'
import { getMemberGroupPermission } from '@/libs/member'
import { CLOSED_POPUP } from '@/libs/storage/session-keys'
import { datadog, datadogLogs } from '@/plugins/datadog'
import { analytics } from '@/plugins/firebase'
import router from '@/router'
import piniaPlugin from '@/store'
import { SESSION } from '@/store/keys'
import { useAppConfigStore } from '@/store/modules/app-config'
import { useMemberGroupStore } from '@/store/modules/member-group'

const session$ = new Subject<SessionStore.State>()

const initializeSession$ = new Subject<void>()

const localKey = 'vuex'
const localRootName = 'SessionNext'
const localFields = [
  'user',
  'selectedBranchId',
  'selectedMemberGroupId',
  'selectedBranchIdForReserveScreen',
] as const

export const useSessionStore = defineStore(SESSION, {
  state: () => {
    const local = (() => {
      try {
        const textData = localStorage.getItem(localKey)
        const jsonData = textData ? JSON.parse(textData) : null

        if (!jsonData?.[localRootName]) {
          return null
        }

        return jsonData[localRootName] as Pick<
          SessionStore.State,
          (typeof localFields)[number]
        >
      } catch {
        return null
      }
    })()

    return {
      user: local?.user || null,
      memberGroups: [],
      memberGroup: null,
      branch: null,
      latestContractBranch: null,
      selectedMemberGroupId: local?.selectedMemberGroupId || null,
      selectedBranchId: local?.selectedBranchId || null,
      selectedBranchIdForReserveScreen:
        local?.selectedBranchIdForReserveScreen || null,
      accessibleBranches: [],
      accessibleBranchesMap: new Map(),
      serviceTypes: [],
    } as SessionStore.State
  },

  getters: {
    isLogin(): boolean {
      return !!this.user?.memberId
    },

    /**
     * 그룹 권한
     */
    groupPermission(): GroupPermissionCode {
      return getMemberGroupPermission(this.memberGroup)
    },

    /**
     * 게스트
     */
    isGuest(): boolean {
      return this.groupPermission === 'GUEST'
    },

    /**
     * 입주예정자
     */
    isPreResident(): boolean {
      return this.groupPermission === 'PRE_RESIDENT'
    },

    /**
     * 입주자
     */
    isResident(): boolean {
      return this.groupPermission === 'RESIDENT'
    },

    /**
     * 퇴주자
     */
    isLeaver(): boolean {
      return this.groupPermission === 'LEAVER'
    },

    /**
     * 게스트 또는 퇴주자
     */
    isGuestOrLeaver(): boolean {
      return this.isGuest || this.isLeaver
    },

    selectedBranch(): IMemberGroup.AccessibleBranchBySpaceCategory | null {
      const selectedBranchId = this.selectedBranchId ?? this.branch?.id
      if (!selectedBranchId) return null
      const selectedBranch = this.accessibleBranches.find((row) => {
        return row.id === selectedBranchId
      })
      return selectedBranch ?? null
    },

    selectedBranchForReserveScreen(): IMemberGroup.AccessibleBranchBySpaceCategory | null {
      const selectedBranchId =
        this.selectedBranchIdForReserveScreen ?? this.latestContractBranch?.id
      if (!selectedBranchId) return null
      const selectedBranch = this.accessibleBranches.find((row) => {
        return row.id === selectedBranchId
      })
      return selectedBranch ?? null
    },

    isAccessibleBranch(): (branchId?: number) => boolean {
      return (branchId) => {
        if (!this.isResident) {
          return false
        }
        if (branchId) {
          const branch = this.accessibleBranches.find((row) => {
            return row.id === branchId
          })
          return !!branch?.isActive
        }
        return !!this.selectedBranch?.isActive
      }
    },

    isBranchContractedOnDate(): ({
      selectedDate,
      branchId,
    }: {
      selectedDate: string | null
      branchId?: number
    }) => boolean {
      return ({ selectedDate, branchId }) => {
        if (!this.isResident) {
          return false
        }
        const selectedBranchId = branchId || this.selectedBranch?.id
        if (selectedBranchId) {
          const branch = this.accessibleBranches.find((row) => {
            return row.id === selectedBranchId
          })
          const isWithinRange = branch?.contracts?.some((contract) => {
            const { startDate, closeDate } = contract
            return dayjs(selectedDate).isBetween(
              dayjs(startDate),
              dayjs(closeDate),
              null,
              '[]',
            )
          })

          return !!isWithinRange
        }
        return false
      }
    },

    /**
     * 현재 그룹의 role
     *
     * user.role은 소속된 그룹들 중 가장 높은 role을 보여주고 있는 것 같음
     */
    memberRoleForGroup(): MemberRole | null {
      return this.memberGroup?.role ?? null
    },

    /**
     * 계약정보
     */
    contract(): SessionStore.State.MemberGroupListItem['contract'] | null {
      const memberGroupId = this.memberGroup?.id ?? null
      if (!memberGroupId) {
        return null
      }
      const memberGroup = this.memberGroups.find((row) => {
        return row.memberGroupId === memberGroupId
      })
      return memberGroup?.contract ?? null
    },

    isInsider(): boolean {
      return this.user?.email.trim().endsWith('fastfive.co.kr') || false
    },
  },

  actions: {
    setUser(user: SessionStore.State.User | null) {
      this.user = user
    },
    patchUser(user: Partial<SessionStore.State.User> = {}) {
      if (this.user) {
        this.user = {
          ...this.user,
          ...user,
        }
      }
    },
    setMemberGroups(memberGroups: SessionStore.State.MemberGroupListItem[]) {
      this.memberGroups = memberGroups
    },
    setBranchId(branchId: number | null = null) {
      this.selectedBranchId = branchId
    },
    setBranchIdForReserveScreen(branchId: number | null = null) {
      this.selectedBranchIdForReserveScreen = branchId
    },
    setSelectedMemberGroupId(memberGroupId: number | null = null) {
      this.selectedMemberGroupId = memberGroupId

      analytics.setUserProperties({
        member_group_id: memberGroupId,
      })
    },
    setDefaultMemberGroup(
      payload: SessionStore.Mutation.UpdateDefaultMemberGroupPayload,
    ) {
      const nextMemberGroup = payload?.memberGroup ?? null
      const nextBranch = payload?.branch ?? null
      const isChangedMemberGroupId = !!(
        this.memberGroup && nextMemberGroup?.id !== this.memberGroup?.id
      )

      if (
        isChangedMemberGroupId ||
        !this.selectedBranchId ||
        !this.accessibleBranchesMap.has(this.selectedBranchId)
      ) {
        this.selectedBranchId = nextBranch?.id ?? null
      }

      if (
        isChangedMemberGroupId ||
        !this.selectedBranchIdForReserveScreen ||
        !this.accessibleBranchesMap.has(this.selectedBranchIdForReserveScreen)
      ) {
        this.selectedBranchIdForReserveScreen = nextBranch?.id ?? null
      }

      this.memberGroup = nextMemberGroup
      this.branch = nextBranch
      this.setSelectedMemberGroupId(nextMemberGroup?.id ?? null)
      this.serviceTypes = payload?.serviceTypes ?? []
    },
    setAccessibleBranches(
      branches: IMemberGroup.AccessibleBranchBySpaceCategory[] = [],
    ) {
      this.accessibleBranches = branches
      this.accessibleBranchesMap.clear()
      branches.forEach((branch) => {
        this.accessibleBranchesMap.set(branch.id, branch)
      })
    },
    deleteSession() {
      this.user = null
      this.memberGroups = []
      this.memberGroup = null
      this.branch = null
      this.setSelectedMemberGroupId(null)
      this.selectedBranchId = null
      this.selectedBranchIdForReserveScreen = null
      this.accessibleBranches = []
      this.latestContractBranch = null
      this.serviceTypes = []
    },
    setLatestContractBranch(branch: SessionStore.State.Branch | null = null) {
      this.latestContractBranch = branch
    },
    async initialize() {
      const appConfigStore = useAppConfigStore()

      try {
        if (await appConfigStore.checkSystemMaintenance()) return
        await appConfigStore.checkUpdate()

        if (this.user) {
          await this.initializeSession()
        } else {
          await this.clearSession()
        }
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * 세션 정보 로드
     */
    async initializeSession() {
      try {
        const profileResponse = await userApi.getProfile()
        const userProfile =
          (profileResponse?.data?.userProfile as SessionStore.State.User) ||
          undefined

        if (userProfile?.memberId) {
          const strMemberId = String(userProfile.memberId)
          const promises: (() => any)[] = []

          this.setUser(userProfile)
          analytics.setUserId(userProfile.memberId)
          datadog.setUser({
            id: strMemberId,
          })
          datadogLogs.setUser({
            id: strMemberId,
          })

          promises.push(() => {
            return userApi
              .getMemberGroups()
              .then((res) => {
                this.setMemberGroups(res?.data?.memberGroups ?? [])
              })
              .catch((error) => {
                console.error(error)
              })
          })

          promises.push(async () => {
            let payload:
              | SessionStore.Action.SelectDefaultMemberGroupSettingPayload
              | undefined
            if (this.selectedBranchId && this.selectedMemberGroupId) {
              payload = {
                branchId: this.selectedBranchId,
                memberGroupId: this.selectedMemberGroupId,
              }
            }
            await this.selectDefaultMemberGroupSetting(payload)
          })

          await Promise.all(promises.map((fn) => fn()))
        }
      } catch {
        // 일반적으로 세션 만료로 인한 오류가 발생
        await this.clearSession()
        router.replace({
          name: 'SignIn',
        })
      }
    },
    /**
     * 세션 정보 로드 (500ms 쓰로틀링됨)
     */
    throttledInitializeSession() {
      return new Promise<void>((resolve) => {
        const subscription = initializeSession$.subscribe(() => {
          subscription.unsubscribe()
          resolve()
        })

        initializeSession$.next()
      })
    },
    async signUp(payload: AuthSignUpApiPayload) {
      return authApi
        .signUp(payload)
        .then(async (data) => {
          const marketingUpdatedAt = data?.marketingUpdatedAt ?? null
          if (!data.success) {
            throw data
          }
          await this.initializeSession()
          return {
            marketingUpdatedAt,
          }
        })
        .catch((error) => {
          throw error
        })
    },
    async signIn(payload: SessionStore.Action.SignInPayload) {
      const response = await authApi.signIn({
        application: payload.application ?? 'web',
        email: payload.email,
        password: payload.password,
      })
      if (response.data.success) {
        await this.initializeSession()
      }
      return response.data.success
    },
    /**
     * 로그아웃
     */
    async signOut() {
      const response = await authApi.signOut()
      await this.clearSession()
      return response.data.success
    },
    /**
     * 탈퇴
     */
    async quit() {
      const response = await userApi.deleteUser()
      await this.clearSession()
      return response.data.success
    },
    async clearSession() {
      this.deleteSession()
      sessionStorage.removeItem(CLOSED_POPUP)
      clearHttpCache()
    },
    async selectBranchId(branchId: number | null = null) {
      this.setBranchId(branchId)
    },
    async selectBranchIdForReserveScreen(branchId: number | null = null) {
      this.setBranchIdForReserveScreen(branchId)
    },
    async getLatestContractBranch(memberGroupId: number) {
      return userApi
        .getDefaultMemberGroupSetting({
          memberGroupId,
          branchId: undefined,
        })
        .then((res) => {
          const memberGroupSetting = res?.data?.memberGroupSetting
          const branch = memberGroupSetting?.branch
          this.setLatestContractBranch(branch)
        })
    },
    /**
     * 멤버 소속 그룹(memberGroup), 지점(branch) 가져오기
     */
    async selectDefaultMemberGroupSetting(
      payload: SessionStore.Action.SelectDefaultMemberGroupSettingPayload = {},
    ) {
      const nextMemberGroupId = payload?.memberGroupId ?? this.memberGroup?.id
      const nextBranchId = payload?.branchId ?? this.branch?.id
      return userApi
        .getDefaultMemberGroupSetting({
          memberGroupId: nextMemberGroupId,
          branchId: nextBranchId,
        })
        .then(async (res) => {
          const memberGroupSetting = res?.data?.memberGroupSetting
          const branch = memberGroupSetting?.branch
          const memberGroup = memberGroupSetting?.memberGroup as SessionStore.State.MemberGroup; // prettier-ignore
          const serviceTypes = memberGroupSetting?.memberGroupTypes as IMemberGroup.ServiceType[] ?? []; // prettier-ignore
          let isAllowed = false
          switch (getMemberGroupPermission(memberGroup)) {
            case 'PRE_RESIDENT':
            case 'RESIDENT':
              isAllowed = true
              break
          }
          // 지점에 대한 접은은 입주 예정자부터 가능
          if (memberGroup?.id && isAllowed) {
            await Promise.all([
              this.fetchMemberGroupDetail(memberGroup.id),
              // 최근 계약 지점을 불러온다.
              this.getLatestContractBranch(memberGroup.id),
            ])
          } else {
            this.setAccessibleBranches([])
            this.setLatestContractBranch(null)
          }
          this.setDefaultMemberGroup({
            branch,
            memberGroup,
            serviceTypes,
          })
        })
    },
    async fetchMemberGroupDetail(memberGroupId: number) {
      const memberGroupStore = useMemberGroupStore()

      try {
        const [branchList, _] = await Promise.all([
          MemberGroupApi.getMemberGroupBranchList({
            memberGroupId,
          }),
          memberGroupStore.load(memberGroupId),
        ])
        const branches = branchList?.accessibleBranches ?? []

        this.setAccessibleBranches(branches)
      } catch (error) {
        this.setAccessibleBranches([])
      }
    },
  },
})

initializeSession$
  .pipe(
    throttleTime(500, undefined, {
      leading: false,
      trailing: true,
    }),
  )
  .subscribe(async () => {
    const sessionStore = useSessionStore()

    sessionStore.initializeSession()
  })

session$
  .pipe(
    throttleTime(500, undefined, {
      leading: true,
      trailing: true,
    }),
  )
  .subscribe((state) => {
    const json = {}

    for (const field of localFields) {
      json[field] = state[field] || null
    }

    localStorage.setItem(
      localKey,
      JSON.stringify({
        [localRootName]: json,
      }),
    )
  })

useSessionStore(piniaPlugin).$subscribe((_, state) => {
  session$.next(state)
})
