import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lottie_player = _resolveComponent("lottie-player")!

  return (_openBlock(), _createBlock(_component_lottie_player, {
    ref: "playerRef",
    autoplay: _ctx.autoplay,
    background: _ctx.background,
    controls: _ctx.controls,
    count: _ctx.count,
    direction: _ctx.direction,
    hover: _ctx.hover,
    loop: _ctx.loop,
    mode: _ctx.mode,
    preserveAspectRatio: _ctx.preserveAspectRatio,
    renderer: _ctx.renderer,
    speed: _ctx.speed,
    src: _ctx.src,
    intermission: _ctx.intermission,
    style: _normalizeStyle(_ctx.styles)
  }, null, 8, ["autoplay", "background", "controls", "count", "direction", "hover", "loop", "mode", "preserveAspectRatio", "renderer", "speed", "src", "intermission", "style"]))
}