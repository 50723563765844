import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["stroke"]
const _hoisted_5 = ["stroke"]
const _hoisted_6 = ["stroke"]
const _hoisted_7 = ["stroke"]
const _hoisted_8 = ["fill"]
const _hoisted_9 = ["fill"]
const _hoisted_10 = ["fill"]
const _hoisted_11 = ["fill"]
const _hoisted_12 = ["fill", "stroke"]
const _hoisted_13 = ["stroke"]
const _hoisted_14 = ["fill", "stroke"]
const _hoisted_15 = ["stroke"]
const _hoisted_16 = ["stroke"]
const _hoisted_17 = ["stroke"]
const _hoisted_18 = ["fill"]
const _hoisted_19 = ["fill"]
const _hoisted_20 = ["fill"]
const _hoisted_21 = ["fill"]
const _hoisted_22 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    (_ctx.type === 'off')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("path", {
            d: "M3.79055 11.7445H13.9521C13.9786 11.7445 14.005 11.7472 14.031 11.7524L19.5906 12.8703V20.2109C19.5906 20.9841 18.9638 21.6109 18.1906 21.6109H5.19055C4.41735 21.6109 3.79055 20.9841 3.79055 20.2109V11.7445Z",
            stroke: _ctx.stroke,
            "stroke-width": "1.2"
          }, null, 8, _hoisted_2),
          _createElementVNode("path", {
            d: "M11.6906 21.3594L11.6906 11.3594",
            stroke: _ctx.stroke,
            "stroke-width": "1.2"
          }, null, 8, _hoisted_3),
          _createElementVNode("path", {
            d: "M3.4359 6.15223C3.47938 5.93564 3.69021 5.7953 3.9068 5.83877L21.05 9.27978C21.2666 9.32325 21.4069 9.53408 21.3635 9.75067L20.7127 12.9926C20.6693 13.2092 20.4584 13.3496 20.2418 13.3061L3.09864 9.86509C2.88204 9.82161 2.7417 9.61078 2.78518 9.39419L3.4359 6.15223Z",
            stroke: _ctx.stroke,
            "stroke-width": "1.2"
          }, null, 8, _hoisted_4),
          _createElementVNode("path", {
            d: "M11.6383 11.7472L12.5969 6.97119",
            stroke: _ctx.stroke,
            "stroke-width": "1.2"
          }, null, 8, _hoisted_5),
          _createElementVNode("path", {
            d: "M4.57513 2.37994C5.52862 5.05555 6.96073 6.33673 9.6353 6.87357",
            stroke: _ctx.stroke,
            "stroke-width": "1.2",
            "stroke-linecap": "round"
          }, null, 8, _hoisted_6),
          _createElementVNode("path", {
            d: "M21.7687 5.83099C19.8565 7.93141 18.041 8.56071 15.3664 8.02386",
            stroke: _ctx.stroke,
            "stroke-width": "1.2",
            "stroke-linecap": "round"
          }, null, 8, _hoisted_7),
          _createElementVNode("mask", {
            id: "path-7-inside-1_168_158",
            fill: _ctx.color
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("path", {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M19.0928 6.78449C18.828 8.10335 17.5443 8.9579 16.2254 8.69318C16.2207 8.69222 16.2159 8.69125 16.2111 8.69026L11.4496 7.73452L15.0544 4.51368C15.0635 4.50533 15.0726 4.49706 15.0818 4.48886C15.6339 3.99629 16.4028 3.76034 17.1841 3.91715C18.5029 4.18188 19.3575 5.46563 19.0928 6.78449Z"
            }, null, -1)
          ]), 8, _hoisted_8),
          _createElementVNode("path", {
            d: "M16.2111 8.69026L16.4542 7.51511L16.4473 7.51373L16.2111 8.69026ZM11.4496 7.73452L10.6501 6.83968L8.86036 8.43874L11.2134 8.91105L11.4496 7.73452ZM15.0544 4.51368L15.8539 5.40852L15.8604 5.40277L15.8667 5.39692L15.0544 4.51368ZM15.0818 4.48886L15.8807 5.38429L15.8807 5.38429L15.0818 4.48886ZM15.9893 9.86971C17.9579 10.2649 19.8742 8.98929 20.2693 7.02065L17.9162 6.54834C17.7819 7.21742 17.1307 7.65094 16.4616 7.51664L15.9893 9.86971ZM15.968 9.86539C15.9751 9.86685 15.9822 9.86829 15.9893 9.86971L16.4616 7.51664C16.4591 7.51615 16.4566 7.51564 16.4542 7.51514L15.968 9.86539ZM16.4473 7.51373L11.6857 6.55799L11.2134 8.91105L15.975 9.86679L16.4473 7.51373ZM12.2491 8.62936L15.8539 5.40852L14.2548 3.61883L10.6501 6.83968L12.2491 8.62936ZM14.2829 3.59344C14.2692 3.60567 14.2556 3.618 14.2421 3.63043L15.8667 5.39692C15.8713 5.39267 15.876 5.38846 15.8807 5.38429L14.2829 3.59344ZM17.4202 2.74062C16.2533 2.50639 15.1045 2.86044 14.2829 3.59344L15.8807 5.38429C16.1633 5.13214 16.5523 5.01428 16.9479 5.09369L17.4202 2.74062ZM20.2693 7.02065C20.6645 5.052 19.3889 3.13577 17.4202 2.74062L16.9479 5.09369C17.617 5.22799 18.0505 5.87925 17.9162 6.54834L20.2693 7.02065Z",
            fill: _ctx.stroke,
            mask: "url(#path-7-inside-1_168_158)"
          }, null, 8, _hoisted_9),
          _createElementVNode("mask", {
            id: "path-9-inside-2_168_158",
            fill: _ctx.color
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("path", {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M7.15343 4.38802C6.88871 5.70689 7.74326 6.99064 9.06212 7.25536C9.06708 7.25636 9.07204 7.25734 9.07701 7.2583L13.8382 8.21397L11.7498 3.83962C11.7465 3.83241 11.7431 3.82521 11.7396 3.81803C11.4204 3.15056 10.802 2.63615 10.0208 2.47934C8.70191 2.21461 7.41816 3.06916 7.15343 4.38802Z"
            }, null, -1)
          ]), 8, _hoisted_10),
          _createElementVNode("path", {
            d: "M9.07701 7.2583L9.30594 6.08032L9.31316 6.08177L9.07701 7.2583ZM13.8382 8.21397L14.9211 7.69697L15.9551 9.86281L13.602 9.3905L13.8382 8.21397ZM11.7498 3.83962L10.6669 4.35663L10.6623 4.3468L11.7498 3.83962ZM11.7396 3.81803L10.6571 4.33581L10.6571 4.33581L11.7396 3.81803ZM8.82597 8.43189C6.85732 8.03674 5.58175 6.12051 5.9769 4.15187L8.32997 4.62418C8.19567 5.29326 8.6292 5.94453 9.29828 6.07883L8.82597 8.43189ZM8.84807 8.43626C8.84066 8.43482 8.83329 8.43336 8.82597 8.43189L9.29828 6.07883C9.30087 6.07935 9.30343 6.07985 9.30594 6.08034L8.84807 8.43626ZM9.31316 6.08177L14.0743 7.03743L13.602 9.3905L8.84085 8.43483L9.31316 6.08177ZM12.7553 8.73096L10.6669 4.35661L12.8327 3.32262L14.9211 7.69697L12.7553 8.73096ZM12.8222 3.30026C12.8273 3.31095 12.8324 3.32167 12.8374 3.33243L10.6623 4.3468C10.6606 4.34314 10.6588 4.33947 10.6571 4.33581L12.8222 3.30026ZM10.2569 1.3028C11.4239 1.53703 12.3471 2.30699 12.8222 3.30026L10.6571 4.33581C10.4937 3.99414 10.1802 3.73527 9.78462 3.65587L10.2569 1.3028ZM5.9769 4.15187C6.37205 2.18322 8.28828 0.907654 10.2569 1.3028L9.78462 3.65587C9.11553 3.52157 8.46427 3.9551 8.32997 4.62418L5.9769 4.15187Z",
            fill: _ctx.stroke,
            mask: "url(#path-9-inside-2_168_158)"
          }, null, 8, _hoisted_11)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("path", {
            d: "M3.94061 12.8945H13.9522C13.9687 12.8945 13.9852 12.8962 14.0015 12.8994L19.4406 13.9931V20.2109C19.4406 20.9013 18.881 21.4609 18.1906 21.4609H5.19061C4.50026 21.4609 3.94061 20.9013 3.94061 20.2109V12.8945Z",
            fill: _ctx.color,
            stroke: _ctx.color,
            "stroke-width": "1.5"
          }, null, 8, _hoisted_12),
          _createElementVNode("path", {
            d: "M11.6906 21L11.6906 13.5",
            stroke: _ctx.stroke,
            "stroke-width": "1.5"
          }, null, 8, _hoisted_13),
          _createElementVNode("path", {
            d: "M3.58303 6.18175C3.6102 6.04638 3.74197 5.95867 3.87734 5.98584L21.0205 9.42684C21.1559 9.45402 21.2436 9.58579 21.2165 9.72115L20.6261 12.6625C20.5989 12.7979 20.4671 12.8856 20.3318 12.8584L3.18856 9.4174C3.05319 9.39022 2.96547 9.25845 2.99264 9.12309L3.58303 6.18175Z",
            fill: _ctx.color,
            stroke: _ctx.color,
            "stroke-width": "1.5"
          }, null, 8, _hoisted_14),
          _createElementVNode("path", {
            d: "M11.6383 11.7472L12.597 6.97119",
            stroke: _ctx.stroke,
            "stroke-width": "1.5"
          }, null, 8, _hoisted_15),
          _createElementVNode("path", {
            d: "M4.57513 2.37994C5.52862 5.05555 6.96073 6.33673 9.6353 6.87357",
            stroke: _ctx.color,
            "stroke-width": "1.2",
            "stroke-linecap": "round"
          }, null, 8, _hoisted_16),
          _createElementVNode("path", {
            d: "M21.7687 5.83099C19.8566 7.93141 18.041 8.56071 15.3664 8.02386",
            stroke: _ctx.color,
            "stroke-width": "1.2",
            "stroke-linecap": "round"
          }, null, 8, _hoisted_17),
          _createElementVNode("mask", {
            id: "path-7-inside-1_168_183",
            fill: _ctx.stroke
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("path", {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M19.0928 6.78449C18.8281 8.10335 17.5444 8.9579 16.2255 8.69318C16.2207 8.69222 16.2159 8.69125 16.2112 8.69026L11.4496 7.73452L15.0544 4.51368C15.0635 4.50533 15.0726 4.49706 15.0818 4.48886C15.6339 3.99629 16.4029 3.76034 17.1841 3.91715C18.503 4.18188 19.3576 5.46563 19.0928 6.78449Z"
            }, null, -1)
          ]), 8, _hoisted_18),
          _createElementVNode("path", {
            d: "M16.2112 8.69026L16.4542 7.51511L16.4473 7.51373L16.2112 8.69026ZM11.4496 7.73452L10.6501 6.83968L8.86043 8.43874L11.2135 8.91105L11.4496 7.73452ZM15.0544 4.51368L15.854 5.40852L15.8604 5.40277L15.8668 5.39692L15.0544 4.51368ZM15.0818 4.48886L15.8807 5.38429L15.8807 5.38429L15.0818 4.48886ZM15.9893 9.86971C17.958 10.2649 19.8742 8.98929 20.2694 7.02065L17.9163 6.54834C17.782 7.21742 17.1307 7.65094 16.4617 7.51664L15.9893 9.86971ZM15.9681 9.86539C15.9752 9.86685 15.9823 9.86829 15.9893 9.86971L16.4617 7.51664C16.4592 7.51615 16.4567 7.51564 16.4542 7.51514L15.9681 9.86539ZM16.4473 7.51373L11.6858 6.55799L11.2135 8.91105L15.975 9.86679L16.4473 7.51373ZM12.2492 8.62936L15.854 5.40852L14.2549 3.61883L10.6501 6.83968L12.2492 8.62936ZM14.2829 3.59344C14.2692 3.60567 14.2556 3.618 14.2421 3.63043L15.8668 5.39692C15.8714 5.39267 15.876 5.38846 15.8807 5.38429L14.2829 3.59344ZM17.4203 2.74062C16.2534 2.50639 15.1045 2.86044 14.2829 3.59344L15.8807 5.38429C16.1633 5.13214 16.5524 5.01428 16.948 5.09369L17.4203 2.74062ZM20.2694 7.02065C20.6645 5.052 19.3889 3.13577 17.4203 2.74062L16.948 5.09369C17.6171 5.22799 18.0506 5.87925 17.9163 6.54834L20.2694 7.02065Z",
            fill: _ctx.color,
            mask: "url(#path-7-inside-1_168_183)"
          }, null, 8, _hoisted_19),
          _createElementVNode("mask", {
            id: "path-9-inside-2_168_183",
            fill: _ctx.stroke
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("path", {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M7.1535 4.38802C6.88877 5.70689 7.74332 6.99064 9.06218 7.25536C9.06715 7.25636 9.07211 7.25734 9.07707 7.2583L13.8382 8.21397L11.7499 3.83962C11.7465 3.83241 11.7431 3.82521 11.7397 3.81803C11.4204 3.15056 10.8021 2.63615 10.0208 2.47934C8.70197 2.21461 7.41822 3.06916 7.1535 4.38802Z"
            }, null, -1)
          ]), 8, _hoisted_20),
          _createElementVNode("path", {
            d: "M9.07707 7.2583L9.306 6.08032L9.31322 6.08177L9.07707 7.2583ZM13.8382 8.21397L14.9212 7.69697L15.9551 9.86281L13.6021 9.3905L13.8382 8.21397ZM11.7499 3.83962L10.6669 4.35663L10.6623 4.3468L11.7499 3.83962ZM11.7397 3.81803L10.6571 4.33581L10.6571 4.33581L11.7397 3.81803ZM8.82603 8.43189C6.85738 8.03674 5.58181 6.12051 5.97696 4.15187L8.33003 4.62418C8.19573 5.29326 8.62926 5.94453 9.29834 6.07883L8.82603 8.43189ZM8.84813 8.43626C8.84072 8.43482 8.83335 8.43336 8.82603 8.43189L9.29834 6.07883C9.30094 6.07935 9.30349 6.07985 9.306 6.08034L8.84813 8.43626ZM9.31322 6.08177L14.0744 7.03743L13.6021 9.3905L8.84091 8.43483L9.31322 6.08177ZM12.7553 8.73096L10.667 4.35661L12.8328 3.32262L14.9212 7.69697L12.7553 8.73096ZM12.8222 3.30026C12.8273 3.31095 12.8324 3.32167 12.8374 3.33243L10.6623 4.3468C10.6606 4.34314 10.6589 4.33947 10.6571 4.33581L12.8222 3.30026ZM10.257 1.3028C11.4239 1.53703 12.3472 2.30699 12.8222 3.30026L10.6571 4.33581C10.4937 3.99414 10.1803 3.73527 9.78468 3.65587L10.257 1.3028ZM5.97696 4.15187C6.37211 2.18322 8.28834 0.907654 10.257 1.3028L9.78468 3.65587C9.1156 3.52157 8.46433 3.9551 8.33003 4.62418L5.97696 4.15187Z",
            fill: _ctx.color,
            mask: "url(#path-9-inside-2_168_183)"
          }, null, 8, _hoisted_21),
          _createElementVNode("path", {
            d: "M3.58303 6.18175C3.6102 6.04638 3.74197 5.95867 3.87734 5.98584L21.0205 9.42684C21.1559 9.45402 21.2436 9.58579 21.2165 9.72115L20.6261 12.6625C20.5989 12.7979 20.4671 12.8856 20.3318 12.8584L3.18856 9.4174C3.05319 9.39022 2.96547 9.25845 2.99264 9.12309L3.58303 6.18175Z",
            stroke: _ctx.color,
            "stroke-width": "1.5"
          }, null, 8, _hoisted_22)
        ], 64))
  ], 8, _hoisted_1))
}